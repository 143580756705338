import React, { FC } from 'react'
import { NextSeo } from 'next-seo'
import Head from 'next/head'
import { ISiteExport } from '../@types'
import { IS_PROD, WEB_URL } from '../config'
import moment from 'moment'
import { useRouter } from 'next/router'

export const MetaHead: FC<ISiteExport> = ({ meta }) => {
  const router = useRouter()
  const renderManifest = () => {
    const stringManifest = JSON.stringify(meta.manifest)
    const blob = new Blob([stringManifest], { type: 'application/json' })
    const manifestURL = URL.createObjectURL(blob)
    return <link rel="manifest" href={manifestURL} />
  }

  const get_url_extension = (url) => {
    return url.split(/[#?]/)[0].split('.').pop().trim()
  }

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta name="theme-color" content="#102770" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1,maximum-scale=1,user-scalable=no"
        />
        {IS_PROD && (
          <meta
            name="google-site-verification"
            content="cOoqC89rJomhktrIbyJ4c3pQQAA1UikE93BBpJ8liNc"
          />
        )}
        <link rel="shortcut icon" href={meta.favicon.favicon} />
        <link
          rel="icon"
          href={meta.favicon.favicon16}
          type="image/png"
          sizes="16x16"
        />
        <link
          rel="icon"
          href={meta.favicon.favicon32}
          type="image/png"
          sizes="32x32"
        />
        <link
          rel="icon"
          href={meta.favicon.favicon96}
          type="image/png"
          sizes="96x96"
        />
        {process.browser && renderManifest()}
        {/* Primary Meta Tags */}
        <title>{meta.title}</title>
        <meta name="title" content={meta.title} />
        <meta name="keywords" content={meta.keywords} />
        {Boolean(meta.preLoadImages?.length) &&
          meta.preLoadImages.map((image, i) => (
            <link key={i} rel="preload" href={image} as="image" />
          ))}
      </Head>
      <NextSeo
        title={meta.title}
        description={meta.description}
        canonical={`${WEB_URL}${router.asPath}`}
        twitter={{
          handle: '@handle',
          site: '@site',
          cardType: 'summary_large_image',
        }}
        openGraph={{
          type: 'article',
          locale: 'en_US',
          url: `${WEB_URL}${router.asPath}`,
          title: meta.title,
          description: meta.description,
          images: [
            {
              url: meta.image.url,
              width: meta.image.width,
              height: meta.image.height,
              alt: meta.title,
            },
          ],
          site_name: meta.title,
          article: {
            section: meta?.section,
          },
        }}
        noindex={!IS_PROD}
        nofollow={!IS_PROD}
        additionalMetaTags={[
          {
            httpEquiv: 'x-ua-compatible',
            content: 'IE=edge; chrome=1',
          },
          {
            property: 'og:image:secure_url',
            content: meta.image.url,
          },
          {
            property: 'og:image:type',
            content: `image/${get_url_extension(meta.image.url)}`,
          },
          {
            property: 'og:updated_time',
            content: moment(meta?.updatedAt).format(),
          },
        ]}
        robotsProps={{
          maxSnippet: -1,
          maxImagePreview: 'large',
          maxVideoPreview: -1,
        }}
      />
    </>
  )
}
