// Helper URL
export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL || 'https://rcobawa.betalaunch.io'
export const BASE_URL = process.env.NEXT_PUBLIC_API_URL || 'https://rcobawa-api.betalaunch.io/api'
export const WEB_APP_URL = process.env.NEXT_PUBLIC_WEB_APP_URL || 'https://rcobawa-web.betalaunch.io'
export const INFO_EMAIL = process.env.NEXT_PUBLIC_INFO_EMAIL || 'rcobawa@gmail.com'

// Braintree
export const DROP_IN_AUTH_KEY = process.env.NEXT_PUBLIC_BRAINTREE || 'sandbox_w3y2xskh_cy3snqhfwrhcp5fn'

// Google Analytics
export const GA_MEASUREMENT_ID = process.env.NEXT_PUBLIC_GA_MEASUREMENT_ID || 'G-TQH0CQYPMV'

// Full Story
export const FULL_STORY = process.env.NEXT_PUBLIC_FULL_STORY || '13DQ34'

// Web env
export const IS_PROD = process.env.NEXT_PUBLIC_ENV === 'prod-rcobawa'

// Set site under construction
export const UNDER_CONSTRUCTION = (process.env.NEXT_PUBLIC_UNDER_CONSTRUCTION === "true")